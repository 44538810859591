<template>
  <div>
    <alert v-if="folders.isLoading" class="light-shadow" />
    <div
      v-if="!folders.isLoading && folders.data.length > 0"
      class="semi-transparent-card light-shadow"
    >
      <div class="mb-2 font-weight-bold">
        Please select a folder to share or expand
      </div>
      <div class="row m-0 p-3 d-flex flex-row folders-container">
        <div
          class="
            custom-control custom-checkbox
            col-12 col-md-6 col-lg-4
            pl-0 pl-lg-3
          "
          v-for="f in folders.data"
          :key="f.id"
        >
          <span
            class="mr-5 text-danger pointer-cursor"
            :class="canDeleteFolder(f.id, f.craeted_by) ? '' : 'transparent'"
            v-if="$can({ key: 'providers', expectedPermission: 'edit' })"
            ><i
              class="fas fa-trash-alt"
              @click="
                canDeleteFolder(f.id, f.craeted_by) && deleteFolderModal(f.id)
              "
            ></i
          ></span>
          <input
            type="radio"
            name="customRadio"
            v-model="selectedFolderId"
            v-bind:value="f.id"
            @change="onSelectedFolderChanged"
            class="custom-control-input"
            :id="`folder-${f.id}`"
          />
          <label class="custom-control-label" :for="`folder-${f.id}`"
            >{{ f.name }} ({{ f.count }})
            <i
              v-if="f.action_type"
              class="fa fa-info-circle ml-2 pointer-cursor"
              v-tooltip.top="
                `${
                  f.action_type === 'shared' ? 'Shared' : 'Created'
                } on: ${prettyDate(f.action_date)}`
              "
            ></i>
          </label>
        </div>
      </div>
    </div>
    <div
      class="my-3 m-0 row align-items-center"
      v-if="
        user &&
        !user.isPatient &&
        $can({ key: 'providers', expectedPermission: 'edit' })
      "
    >
      <label class="control-label col-12 col-md-auto pl-0 mb-0 font-weight-bold"
        >Create New Folder</label
      >
      <div class="input-group col-12 col-md-4 pl-0 pl-md-3 pr-0 pr-md-3">
        <input
          type="text"
          v-model="folder"
          class="form-control"
          :disabled="folders.isSaving"
        />
      </div>
      <div class="col-auto mt-3 mt-md-0 pl-0 pl-md-3">
        <save
          @click="createFolder"
          :saving="folders.isSaving"
          classes="btn btn-outline-blue"
        >
          Create
        </save>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Save from "../../../../components/Save";
import Swal from "sweetalert2";
import helpers from "@/utils/helpers";

export default {
  name: "Folders",
  components: { Save },
  data() {
    return {
      folder: "",
      selectedFolderId: undefined,
    };
  },
  computed: {
    ...mapState({
      folders: (state) => state.folders,
      provider: (state) => state.providers.provider,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.get(this.provider.user_id);
  },
  methods: {
    ...mapActions({
      save: "folders/save",
      get: "folders/get",
      deleteFolder: "folders/deleteFolder",
    }),
    canDeleteFolder(folderId, userId) {
      if (!userId && folderId) {
        return true;
      } else if (this.user && folderId) {
        if (this.user.isAdmin || this.user.isManagingAdmin) {
          return true;
        } else if (this.user.isProvider && this.user.user_id == userId) {
          return true;
        }
        return false;
      }
      return false;
    },
    createFolder() {
      const data = {
        name: this.folder,
        user_id: this.provider.user_id,
      };

      this.save(data)
        .then((val) => {
          if (val && val.data) {
            this.get(this.provider.user_id);
          } else {
            Swal.fire("Error", val.error.message, "error");
          }
          this.folder = null;
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire("Error", err.data.error.message, "error");
          }
        });
    },
    deleteFolderModal(folderId) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            var payload = { folderId: folderId, userId: this.provider.user_id };

            vm.deleteFolder(payload).then((deleted) => {
              vm.get(this.provider.user_id);
              if (deleted) {
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your folder has been deleted.",
                  "success"
                );
                vm.onSelectedFolderDeleted();
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
    onSelectedFolderChanged() {
      this.$emit("selectedFolderChanged", this.selectedFolderId);
    },
    onSelectedFolderDeleted() {
      this.$emit("selectedFolderDeleted");
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
  },
};
</script>

<style scoped>
.folders-container {
  box-shadow: none;
}
.custom-control-input {
  z-index: -1 !important;
}
</style>
